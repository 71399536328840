<template>
	<el-pagination
		background
		:page-sizes="[ 10 , 20 , 30 , 40 , 50 ]"
		:page-size="pageSize"
		layout="total, sizes, prev, pager, next, jumper"
		:total="total"
		:current-page="currentPage"
	>
	</el-pagination>
</template>

<script>
	export default{
		data(){
			return {
				
			}
		},
		methods:{
			
		},
		props : [ 'total' , 'pageSize' , 'currentPage' ],
	}
</script>

<style>
</style>
