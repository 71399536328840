<template>
	<div>
		<el-form :inline="true" :model="form" class="">
			<el-form-item label="家庭名称">
				<el-input v-model="form.name" placeholder="请输入家庭名称"></el-input>
			</el-form-item>
			<el-form-item label="家庭电话">
				<el-input v-model="form.phone" placeholder="请输入家庭号码"></el-input>
			</el-form-item>
			<el-form-item label="区域">
			  <qfAddres @change_type="change_qfAddres_type" @handleChange="handleChange" ref="qfAddres" :type="qfAddres_type" />
			</el-form-item>
			<el-form-item label="家庭地址">
				<el-input v-model="form.address" placeholder="请输入家庭地址"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="user_list" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="list" border v-loading="loading" @expand-change="rowClick" style="width: 100%">
			<el-table-column type="expand">
				<template slot-scope="scope">
					<el-table :data="scope.row.list" border row-key="id" style="width: 100%;" lazy v-loading="loadings">
						<el-table-column prop="deviceNo" label="设备编号">
						</el-table-column>
						<el-table-column prop="deviceId" label="设备ID">
						</el-table-column>
						<el-table-column prop="typeName" label="类型名称">
						</el-table-column>
						<el-table-column prop="deviceBrand" label="设备品牌">
						</el-table-column>
						<el-table-column prop="status" width="120" label="设备状态" >
							<template slot-scope="scope">
								<el-tag size="mini" v-if=" scope.row.deviceTypeCode == 6 && scope.row.status == 0 " type="success" disable-transitions>
									开阀
								</el-tag>
								<el-tag size="mini" v-if=" scope.row.deviceTypeCode == 6 && scope.row.status == 1 " type="info" disable-transitions>
									关阀
								</el-tag>
								<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '—' " type="info" disable-transitions>
									{{scope.row.statusText}}
								</el-tag>
								<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '报警' " type="danger" disable-transitions>
									{{scope.row.statusText}}
								</el-tag>
								<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '正常' " type="success" disable-transitions>
									{{scope.row.statusText}}
								</el-tag>
								<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '在线-关机' " :type=" ( scope.row.deviceTypeCode != 1 && scope.row.deviceTypeCode != 7 && scope.row.deviceTypeCode != 8 ) ? 'success' : 'warning'" disable-transitions>
									<span>{{ ( scope.row.deviceTypeCode != 1 && scope.row.deviceTypeCode != 7 && scope.row.deviceTypeCode != 8 ) ? "在线" : scope.row.statusText }}</span>
								</el-tag>
								<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '在线-开机' " type="success" disable-transitions>
									<span>{{ ( scope.row.deviceTypeCode != 1 && scope.row.deviceTypeCode != 7 && scope.row.deviceTypeCode != 8 ) ? "在线" : scope.row.statusText }}</span>
								</el-tag>
								<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '在线' " type="success" disable-transitions>
									<span>{{ ( scope.row.deviceTypeCode != 1 && scope.row.deviceTypeCode != 7 && scope.row.deviceTypeCode != 8 ) ? "在线" : scope.row.statusText }}</span>
								</el-tag>
								<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '离线' " type="danger" disable-transitions>
									{{scope.row.statusText}}
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="lastOnlineTime" label="最后上线时间">
						</el-table-column>
						<el-table-column prop="lastOfflineTime" label="最后离线时间">
						</el-table-column>
						<el-table-column label="操作" fixed="right" width="100">
							<template slot-scope="scope">
								<el-button size="mini" v-if="scope.row.deviceTypeCode != 6 && scope.row.deviceTypeCode != 5 && scope.row.statusOperator == '开关'" @click="sureIt(scope.row)" type="primary" icon="el-icon-open" plain style="margin-right: 10px;margin-bottom: 5px;">开机/关机</el-button>
								<el-button size="mini" v-if="scope.row.deviceTypeCode != 6 && scope.row.deviceTypeCode != 5 && scope.row.statusOperator == '在线-开机'" @click="changeButton(scope.row,2)" type="success" icon="el-icon-open" plain style="margin-right: 10px;margin-bottom: 5px;">开机</el-button>
								<el-button size="mini" v-if="scope.row.deviceTypeCode != 6 && scope.row.deviceTypeCode != 5 && scope.row.statusOperator == '在线-关机' " @click="changeButton(scope.row,2)" type="warning" icon="el-icon-open" plain style="margin-right: 10px;margin-bottom: 5px;">关机</el-button>
								<el-button size="mini" v-if="scope.row.deviceTypeCode == 5 && scope.row.statusText != '离线' && scope.row.statusText != '—' " @click="changeButton(scope.row,2)" type="warning" icon="el-icon-open" plain style="margin-right: 10px;margin-bottom: 5px;">关机</el-button>
								<el-button size="mini" v-if=" scope.row.deviceTypeCode == 0 " @click="gfCheck(scope.row)" type="danger" icon="el-icon-open" plain style="margin-right: 10px;margin-bottom: 5px;">关阀</el-button>
							</template>
						</el-table-column>
						<el-table-column label="解除绑定" fixed="right" width="140">
							<template slot-scope="scope">
								<el-popconfirm title="是否要解绑该设备？" icon-color="red" @confirm="delDevice( scope.row )"
									style="margin-right: 5px;">
									<el-button slot="reference" size="mini" type="danger">解绑</el-button>
								</el-popconfirm>
							</template>
						</el-table-column>
						<el-table-column label="设备端信息" fixed="right" width="140">
							<template slot-scope="scope">
								<el-button slot="reference" size="mini" type="primary" icon="el-icon-view" @click="seeDevice(scope.row)" style="margin-right: 5px;" v-if=" scope.row.deviceTypeCode == 1 ">查看</el-button>
							</template>
						</el-table-column>
						<el-table-column label="设备设置记录" fixed="right" width="140">
							<template slot-scope="scope">
								<el-button slot="reference" size="mini" type="primary" icon="el-icon-view" @click="historyNum(scope.row.deviceNo)" style="margin-right: 5px;" v-if=" scope.row.deviceTypeCode == 1 ">查看</el-button>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<el-table-column prop="name" label="家庭名称">
			</el-table-column>
			<el-table-column prop="phone" label="家庭电话">
			</el-table-column>
			<el-table-column prop="province" label="所在省份">
			</el-table-column>
			<el-table-column prop="city" label="所在城市">
			</el-table-column>
			<el-table-column prop="district" label="所在区域">
			</el-table-column>
			<el-table-column prop="address" label="家庭地址" width="170">
			</el-table-column>
			<el-table-column prop="longitude" label="经度" width="170">
			</el-table-column>
			<el-table-column prop="latitude" label="纬度" width="170">
			</el-table-column>
			<el-table-column prop="createTime" label="创建时间" width="170">
			</el-table-column>
			<el-table-column prop="modifyTime" label="最后修改时间" width="170">
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="140">
				<template slot-scope="scope">
					<!-- <el-button slot="reference" size="mini" type="primary" icon="el-icon-share" @click="addDevice(scope.row)" style="margin-right: 5px;">绑定</el-button>
					<el-button slot="reference" size="mini" type="warning" icon="el-icon-edit" @click="edit(scope.row)" style="margin-right: 5px;">编辑</el-button>
					<el-popconfirm title="是否要删除？" icon-color="red" @confirm="delete_user( scope.row.userName )">
						<el-button slot="reference" size="mini" type="danger" icon="el-icon-delete">删除</el-button>
					</el-popconfirm> -->
					<el-button slot="reference" size="mini" type="primary" icon="el-icon-view" @click="seeFamily(scope.row)" style="margin-right: 5px;">查看成员</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination @current-change="changePage" @size-change="sizeChange" :page-sizes="[ 10 , 20 , 30 , 40]"
				:total="total" :currentPage="form.startPage" :pageSize="form.pageSize" background
				layout="total, sizes, prev, pager, next, jumper">
			</el-pagination>
		</div>
		<el-dialog
			title="提示"
			:visible.sync="dialogVisible"
			width="20%"
			@close="dialogVisible = false">
			<el-radio v-model="radio" label="1" style="margin-left: 20px;">开机</el-radio>
			<el-radio v-model="radio" label="0">关机</el-radio>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="changeButton(opendata , 1)" style="margin-right: 20px;">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog
			title="提示"
			:visible.sync="addShow"
			width="20%"
			@close="addShow = false">
			<el-form ref="form" label-width="80px">
				<el-form-item label="设备编号">
					<el-input v-model="newDeviceNo"></el-input>
				</el-form-item>
				<el-form-item label="设备类型">
					<el-select v-model="deviceTypeCode" placeholder="请选择设备类型">
						<el-option label="" value=""></el-option>
						<el-option v-for=" ( item , i ) in typeList " :key="i" :label="item.typeName" :value="item.typeCode"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addIt" style="margin-right: 20px;">确 定</el-button>
				<el-button @click="addShow = false">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog
			title="提示"
			:visible.sync="gfShow"
			width="20%"
			@close="gfShow = false">
			<div style="color: red;font-weight: bold;">是否确认关阀？</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="gfSure" style="margin-right: 20px;">确 认</el-button>
				<el-button @click="gfShow = false">取 消</el-button>
			</span>
		</el-dialog>
		<edit ref="edit" @success="device_list" />
		<el-drawer
			title="家庭成员"
			:visible.sync="drawer"
			:direction="direction"
			size="20%" >
			<div class="demo-basic--circle" style="display: flex;justify-content: start;">
				<div class="block" v-for="( item , i ) in familyList" :key="i" style="padding: 0px 30px 30px 30px;width: 60px;position: relative;">
					<el-avatar :size="60" :src=" item.avatarUrl == '' ? 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png' : item.avatarUrl "></el-avatar>
					<div style="text-align: center;" :class=" item.creator == 1 ? 'blue' : 'orange' ">{{ item.creator == 1 ? "创建者" : "成员" }}</div>
					<div style="text-align: center;font-size: 13px;position: absolute;width: 100%;margin-left: -30px;">{{ item.nickName }}</div>
				</div>
			</div>	
		</el-drawer>
		<el-drawer
			title="设备设置信息"
			:visible.sync="drawer2"
			:direction="direction"
			size="20%" >
			<div style="text-align: center;color:gray">更新时间：{{ deviceSet.updateTime }}</div>
			<div style="display: flex;flex-wrap: wrap;">
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/kg.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						状态：<span style="">{{ deviceSet.statusText }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/zengya.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						增压：<span style="">{{ deviceSet.pressurizeFunc == null ? "/" : ( deviceSet.pressurizeFunc == 1 ? "开" : "关" ) }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/dxh.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						单次：<span style="">{{ deviceSet.onceFunc == null ? "/" : ( deviceSet.onceFunc == 1 ? "开" : "关" ) }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/diandong.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						点动：<span style="">{{ deviceSet.jogFunc == null ? "/" : ( deviceSet.jogFunc == 1 ? "开" : "关" ) }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/zxh.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						智循环：<span style="">{{ deviceSet.intelligentCycle == null ? "/" : ( deviceSet.intelligentCycle == 1 ? "开" : "关" ) }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/glkg.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						预约开关：<span style="">{{ deviceSet.reservationSwitch == null ? "/" : ( deviceSet.reservationSwitch == 1 ? "开" : "关" ) }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/ld.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						亮度：<span style="" v-if=" deviceSet.brightness == null ">亮度三</span>
						<span style="" v-if=" deviceSet.brightness == 10 ">亮度一</span>
						<span style="" v-if=" deviceSet.brightness == 50 ">亮度二</span>
						<span style="" v-if=" deviceSet.brightness == 80 ">亮度三</span>
						<span style="" v-if=" deviceSet.brightness == 130 ">亮度四</span>
						<span style="" v-if=" deviceSet.brightness == 180 ">亮度五</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/ds.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						预约定时：<span style="">已预约</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/wdsz.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						设置温度：<span style="">{{ deviceSet.temperatureSet == null ? "0" : deviceSet.temperatureSet }}℃</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/dqwd.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						当前水温：<span style="">{{ deviceSet.currentTemperature == null ? "0" : deviceSet.currentTemperature }}℃</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/shui.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						出水温度：<span style="">{{ deviceSet.outletTemperature == null ? "0" : deviceSet.outletTemperature }}℃</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/huo.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						火焰状态：<span style="">{{ deviceSet.fireStatus == null ? "关" : deviceSet.fireStatus }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/llfw.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						水流流量：<span style="">{{ deviceSet.flowNum == null ? "0" : deviceSet.flowNum }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/gsll.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						水流状态：<span style="">{{ deviceSet.flowStatus == null ? "关" : deviceSet.flowStatus }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/fj.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						风机状态：<span style="">{{ deviceSet.fanStatus == null ? "关" : deviceSet.fanStatus }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/sb.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						水泵状态：<span style="">{{ deviceSet.pumpStatus == null ? "关" : deviceSet.pumpStatus }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/fault.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						故障：<span style="">{{ !deviceSet.faultCode || deviceSet.faultCode == '00000000' ? '无故障' : '故障' }}</span><span style="display: inline-block;width: 40px;border: 1px solid #a6a6a6;margin-left: 5px;border-radius: 5px;font-size: 12px;cursor: pointer;" @click="seekFault" v-if=" deviceSet.faultCode && deviceSet.faultCode != '00000000' ">查看</span>
					</div>
				</div>
			</div>
		</el-drawer>
		<el-drawer
			title="设备设置信息"
			:visible.sync="drawer3"
			:direction="direction"
			size="20%" >
			<div style="text-align: center;color:gray">更新时间：{{ deviceSet.updateTime }}</div>
			<div style="display: flex;flex-wrap: wrap;">
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/kg.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						状态：<span style="">{{ deviceSet.statusText }}</span>
					</div>
				</div>
				<div class="padl">
					<div style="margin: 0 auto;width: 50px;">
						<img src="../../../assets/rsqImg/wdsz.png" alt="" style="width: 50px;height:50px;" >
					</div>
					<div style="text-align: center;font-size: 14px;">
						设置温度：<span style="">{{ deviceSet.temperatureSet == null ? "0" : deviceSet.temperatureSet }}℃</span>
					</div>
				</div>
			</div>
		</el-drawer>
		<el-dialog
		  title="故障码"
		  :visible.sync="showFault"
		  width="30%">
		  <span>{{srt1}}</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="showFault = false">取 消</el-button>
		    <el-button type="primary" @click="showFault = false" style="margin-left: 10px;">确 定</el-button>
		  </span>
		</el-dialog>
		<story ref="storyData" @success="init_getTypeList" />
	</div>
</template>

<style>
	.demo-table-expand {
		font-size: 0;
	}

	.demo-table-expand label {
		width: 90px;
		color: #99a9bf;
	}

	.demo-table-expand .el-form-item {
		margin-right: 0;
		margin-bottom: 0;
		width: 50%;
	}
</style>

<script>
	import pagination from '@/components/pagination.vue';
	import edit from './components/edit.vue';
	import qfAddres from '@/components/qf-address/index.vue';
	import story from "./components/story.vue";
	export default {
		data() {
			return {
				loading: false,
				show_edit: false,
				list: [],
				form: {
					startPage:1,
					pageSize:10,
					name:'',
					phone:'',
					province:'',
					city:'',
					district:'',
					address:'',
				},
				total: 0,
				dialogTableVisible: false,
				edit_type: 0,
				loadings: false,
				list2: [],
				openType: 0,
				opendata:{},
				dialogVisible:false,
				radio: '0',
				gengxinData:{},
				addShow:false,
				addData:{},
				newDeviceNo:"",
				addDelType:0,
				deviceTypeCode:'',
				typeList:[],
				gfShow:false,
				gfData:{},
				drawer: false,
				direction: 'rtl',
				familyList:[],
				qfAddres_type:1,
				drawer2:false,
				drawer3:false,
				deviceSet:{},
				showFault:false,
				str:'',
				strArr:[],
				srt1:''
			}
		},
		created() {
			// this.init_getTypeList();
			this.user_list();
		},
		methods: {
			seeDevice( data ){
				if( data.deviceModel == 'X323' ){
					this.drawer3 = true;
					this.$api.oldHeaterstatus( { deviceNo : data.deviceNo } ).then( res => {
						if( res.code == 200 ){
							this.deviceSet = res.data[0];
							this.deviceSet.statusText = data.statusText;
							this.deviceSet.isOpen = 0
						}else{
							this.$message({ type: 'error', message: "获取信息失败", });
						}
					}).catch(res=>{
						this.$message({ type: 'error', message: "获取信息失败", });
					})
				}else{
					this.drawer2 = true;
					this.$api.heater_status( { deviceNo : data.deviceNo } ).then( res => {
						if( res.code == 200 ){
							this.deviceSet = res.data;
							this.deviceSet.statusText = data.statusText;
							this.deviceSet.isOpen = 0
						}else{
							this.$message({ type: 'error', message: "获取信息失败", });
						}
					}).catch(res=>{
						this.$message({ type: 'error', message: "获取信息失败", });
					})
				}
			},
			change_qfAddres_type( e ){
			  this.qfAddres_type = e;
			},
			handleChange( data ){
			  for ( let k in data ) {
			    this.form[ k ] = data[k];
			  }
			},
			gfCheck(data){
				this.gfShow = true
				this.gfData = data
			},
			gfSure(){
				let data = {};
				data.deviceNo = this.gfData.deviceNo;
				data.deviceId = this.gfData.deviceId;
				this.$api.akxCloseValve( data ).then( res => {
					if( res.code == 200 ){
						this.$message({ type: 'success', message: res.data, });
						this.gfShow = false;
					}else{
						this.$message({ type: 'error', message: "操作失败", });
					}
				}).catch(res=>{
					this.$message({ type: 'error', message: "操作失败", });
				})
			},
			addIt(){
				if( this.newDeviceNo == "" ){
					this.$message({
						type: 'error',
						message: "设备编号不能为空",
					});
					return
				}
				if( this.newDeviceNo == "" ){
					this.$message({
						type: 'error',
						message: "设备类型为必选",
					});
					return
				}
				var data = {
					userId : this.addData.id,
					deviceNo : this.newDeviceNo,
					deviceTypeCode : this.deviceTypeCode
				}
				this.$api.bindDevice_bind( data ).then(res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: "绑定成功",
						});
						this.newDeviceNo = "";
						this.deviceTypeCode = ""
						this.addShow = false;
						if( this.addDelType == 1 ){
							this.gengxinUserInfo()
						}
					}else{
						this.$message({
							type: 'error',
							message: "绑定失败"
						});
					}
				});
			},
			// addDevice( data ){
			// 	this.addShow = true;
			// 	this.addData = data;
			// },
			delDevice( data ){
				var delData = {
					id : data.id,
					homeId : this.gengxinData.id,
					typeCode : data.deviceTypeCode
				}
				this.$api.bindDevice_removeBind(delData).then(res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: "解绑成功",
						});
						if( this.addDelType == 1 ){
							this.gengxinUserInfo()
						}
					}else{
						this.$message({
							type: 'error',
							message: "解绑失败"
						});
					}
				});
			},
			changeButton( data , type ){
				// let upData = {
				// 	userName:this.user_info.userName,
				// 	deviceId:data.deviceId,
				// 	deviceTypeCode:data.deviceTypeCode,
				// }
				// if( type == 1 ){
				// 	upData.operationTypeCode = this.radio
				// }else{
				// 	if( data.deviceTypeCode == 5 ){
				// 		upData.operationTypeCode = '02'
				// 	}else{
				// 		if( data.statusOperator == '在线-开机'){
				// 			upData.operationTypeCode = '01'
				// 		}else if( data.statusOperator == '在线-关机' ){
				// 			upData.operationTypeCode = '02'
				// 		}
				// 	}
				// }
				let upData = {};
					upData.deviceId = data.deviceId;
					upData.deviceNo = data.deviceNo;
					upData.deviceTypeCode = data.deviceTypeCode;
					upData.deviceModel = data.deviceModel;
				let parameter = {};
					if( type == 1 ){
						parameter.isOpen = this.radio
					}else{
						if( data.deviceTypeCode == 5 ){
							parameter.isOpen = 0
						}else{
							if( data.statusOperator == '在线-开机'){
								parameter.isOpen = 1
							}else if( data.statusOperator == '在线-关机' ){
								parameter.isOpen = 0
							}
						}
					}
				upData.parameter = parameter;
				this.$api.setFunc_Rsq(upData).then(res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: "操作成功",
						});
						if( type == 1 ){
							this.dialogVisible = false;
						}
						this.gengxinUserInfo()
					}else{
						this.$message({
							type: 'error',
							message: "操作失败"
						});
					}
				});
			},
			sureIt( data ){
				this.dialogVisible = true;
				this.opendata = data;
			},
			rowClick(row, expandedRows ) {
				if (expandedRows.length != 0) {
					this.addDelType = 1
					this.list2 = []
					this.gengxinData = row;
					this.gengxinUserInfo();
				}else{
					this.addDelType = 0
				}
			},
			gengxinUserInfo(){
				var data = {
					homeId: this.gengxinData.id,
				}
				this.$api.bindDevice_bindList(data).then(res => {
					if (res.code == 200) {
						this.list2 = res.data.list
						for( var i = 0 ; i < this.list2.length ; i++ ){
							this.list2[i].statusOperator = ""
							if( this.list2[i].deviceTypeCode == 0 ){
								if( this.list2[i].status == 0 ){
									this.list2[i].statusText = "报警"
								}else if( this.list2[i].status == 1 ){
									this.list2[i].statusText = "正常"
								}else{
									this.list2[i].statusText = "—"
								}
							}else{
								if( this.list2[i].status == 0 ){
									this.list2[i].statusText = "在线-关机"
									this.list2[i].statusOperator = "在线-开机"
								}else if( this.list2[i].status == 1 ){
									this.list2[i].statusText = "在线-开机"
									this.list2[i].statusOperator = "在线-关机"
								}else if( this.list2[i].status == -1 ){
									this.list2[i].statusText = "在线"
									this.list2[i].statusOperator = "开关"
								}else if( this.list2[i].status == -2 ){
									this.list2[i].statusText = "离线"
								}else{
									this.list2[i].statusText = "—"
								}
							}
							this.list.some(item => {
								if(item.id === this.gengxinData.id){
									item.list = this.list2
									return true
								}
							})
						}
					} else {
						if( this.addDelType == 1 && res.message == "根据条件查无数据" ){
							this.list2 = []
							this.list.some(item => {
								if(item.id === this.gengxinData.id){
									item.list = this.list2
									return true
								}
							})
						}else{
							this.$message({
								type: 'error',
								message: res.message
							});
						}
					}
				});
			},
			user_list() {
				this.loading = true;
				this.$api.home_list(this.form).then(res => {
					if (res.code == 200) {
						this.list = res.data.list;
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
						this.list = this.list.map(item => {
							return {
								...item,
								list: []
							}
						})
					} else {
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			sizeChange(e) {
				this.form.pageSize = e;
				this.user_list();
			},
			changePage(e) {
				this.form.startPage = e;
				this.user_list();
			},
			// delete_user(userName) {
			// 	this.$api.user_delete({
			// 		userName
			// 	}).then(res => {
			// 		this.$message({
			// 			type: res.code == 200 ? 'success' : 'error',
			// 			message: res.message
			// 		});
			// 		if (res.code == 200) {
			// 			if (this.list.length == 1 && this.form.startPage > 1) {
			// 				this.form.startPage = this.form.startPage - 1;
			// 			}
			// 			this.user_list();
			// 		}
			// 	});
			// },
			edit_success(e) {
				this.user_list();
			},
			// edit(data) {
			// 	this.$refs.edit.type = 1;
			// 	this.$refs.edit.open(data);
			// },
			device_list(){
				this.user_list();
			},
			
			seeFamily( row ){
				this.drawer = true;
				var data = {
					homeId : row.id
				}
				this.$api.home_user( data ).then(res => {
					if (res.code == 200) {
						this.familyList = res.data;
					} else {
						this.familyList = [];
					}
				});
			},
			handleClose(done) {
				this.$confirm('确认关闭？').then(_ => {done(); }).catch(_ => {});
			},
			historyNum( deviceNo ){
				this.$refs.storyData.open( deviceNo );
			},
			init_getTypeList(){
				this.$api.device_getTypeList().then(res => {
					if (res.code == 200) {
						this.typeList = res.data
					}else{
						this.$message({
							type: 'error',
							message: "获取设备类型失败"
						});
					}
				});
			},
			seekFault(){
				this.showFault = true;
				this.str = this.deviceSet.faultCode;
				this.strArr = this.str.split("");
				this.srt1 = "";
				if( this.strArr[15] == '1' ){
					this.srt1 = this.srt1 + 'F0' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[16] == '1' ){
					this.srt1 = this.srt1 + 'A0' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[17] == '1' ){
					this.srt1 = this.srt1 + 'AF' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[18] == '1' ){
					this.srt1 = this.srt1 + 'AE' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[19] == '1' ){
					this.srt1 = this.srt1 + 'AD' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[20] == '1' ){
					this.srt1 = this.srt1 + 'AC' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[21] == '1' ){
					this.srt1 = this.srt1 + 'AB' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				} 
				if( this.strArr[22] == '1' ){
					this.srt1 = this.srt1 + 'AA' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[23] == '1' ){
					this.srt1 = this.srt1 + 'A9' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[24] == '1' ){
					this.srt1 = this.srt1 + 'A8' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[25] == '1' ){
					this.srt1 = this.srt1 + 'A7' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[26] == '1' ){
					this.srt1 = this.srt1 + 'A6' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[27] == '1' ){
					this.srt1 = this.srt1 + 'A5' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[28] == '1' ){
					this.srt1 = this.srt1 + 'A4' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[29] == '1' ){
					this.srt1 = this.srt1 + 'A3' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[30] == '1' ){
					this.srt1 = this.srt1 + 'A2' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
				if( this.strArr[31] == '1' ){
					this.srt1 = this.srt1 + 'A1' + " ";
				}else{
					this.srt1 = this.srt1 + " ";
				}
			}
		},
		components:{
			edit,qfAddres,story
		}
	}
</script>

<style type="text/css">
	.blue{
		color:#4484ff;
		font-weight: bold;
	}
	.orange{
		color:#ff6a06;
		font-weight: bold;
	}
	.padl{
		/* margin-top: 10px; */
		width: 33.33%;
		/* padding-left: 20px; */
		/* background-color: #444444; */
		/* position: relative; */
		/* padding: 10px; */
		margin: 20px 0px 10px 40px;
		border-radius: 9px;
	}
</style>
