<template>
	<div>
		<el-cascader :disabled="disabled" :clearable="clearable" v-model="value" :options="options" @change="handleChange"></el-cascader>
	</div>
</template>

<script>
	import addressData from './index.js';
	export default{
		data(){
			return {
				options : [],
				value : "",
				province : "",
				city : "",
				district : "",
				disabled : false,
			}
		},
		created(){
			//默认取用户信息中的地址
			this.city = this.user_info.city;
			this.province = this.user_info.province;
			this.district = this.user_info.district;
			//渲染类型
			this[ 'set_options_' + this.type ]();
		},
		methods:{
			clear(){
				this.province = this.city = this.district = this.value = "";
			},
			change_type( type ){
				this.$emit( 'change_type' , type );
			},
			set_address( data ){
				//进来先根据用户信息获取地址
				this.province = data.province;
				this.city = data.city;
				this.district = data.district;
			},
			handleChange( e ){
				if( this.type == 1 ){
					this.province = this.value[0];
					this.city = this.value[1];
					this.district = this.value[2];
				}else if( this.type == 2 ){
					this.province = this.value[0];
					this.city = this.value[1];
				}else if( this.type == 3 ){
					this.city = this.value[0];
					this.district = this.value[1];
				}else if( this.type == 4 ){
					this.province = this.value[0];
				}else if( this.type == 5 ){
					this.city = this.value[0];
				}else if( this.type == 6 ){
					this.district = this.value[0];
				}
				this.$emit( 'handleChange' , {
					province : this.province == "全部" ? "" : this.province ,
					city : this.city == "全部" ? "" : this.city ,
					district :  this.district == "全部" ? "" : this.district ,
				})
			},
			//只选区
			set_options_6(){
				//改变类型
				this.change_type( 6 );
				//确定目前是那个省
				let province_code = "";
				for( let k in addressData['86'] ){
					if( addressData['86'][k] == this.province ){
						province_code = k;
						break;
					}
				}
				//确定目前是那个市
				let city_code = "";
				for( let k in addressData[province_code] ){
					if( addressData[province_code][k] == this.city ){
						city_code = k;
						break;
					}
				}
				let options = this.is_all ? [{ value : "全部" , value_code : "" , label : "全部" }] : [];
				for( let k in addressData[city_code]  ){
					options.push({
						value : addressData[city_code][k],
						value_code : k,
						label : addressData[city_code][k]
					});
				}
				this.options = options;
			},
			//只选市
			set_options_5(){
				//改变类型
				this.change_type( 5 );
				let province_code = "";
				for( let k in addressData['86']  ){
					if( addressData['86'][k] == this.province ){
						province_code = k;
						break;
					}
				}
				let options = this.is_all ? [{ value : "全部" , value_code : "" , label : "全部" }] : [];
				for( let k in addressData[province_code]  ){
					options.push({
						value : addressData[province_code][k],
						value_code : k,
						label : addressData[province_code][k]
					});
				}
				this.options = options;
			},
			//只选省
			set_options_4(){
				//改变类型
				this.change_type( 4 );
				//type表示是否设置默认地址
				let options = this.is_all ? [{ value : "全部" , value_code : "" , label : "全部" }] : [];
				for( let k in addressData['86']  ){
					options.push({
						value : addressData['86'][k],
						value_code : k,
						label : addressData['86'][k]
					});
				}
				this.options = options;
			},
			//只选市和区
			set_options_3(){
				//改变类型
				this.change_type( 3 );
				let province_code = "";
				for( let k in addressData['86']  ){
					if( addressData['86'][k] == this.province ){
						province_code = k;
						break;
					}
				}
				let options = this.is_all ? [{ value : "全部" , value_code : "" , label : "全部" }] : [];
				for( let k in addressData[province_code]  ){
					options.push({
						value : addressData[province_code][k],
						value_code : k,
						label : addressData[province_code][k],
						children : []
					});
				}
				options.forEach( ( item , i ) => {
					if( item.children && this.is_all  ){
						item.children.push({ value : "全部" , value_code : "" , label : "全部" });
					}
					for( let k in addressData[ item.value_code ] ){
						item.children.push({
							value : addressData[ item.value_code ][k],
							value_code : k,
							label : addressData[ item.value_code ][k]
						});
					}
				});
				this.options = options;
			},
			//只选省和市
			set_options_2(){
				//改变类型
				this.change_type( 2 );
				let options = this.is_all ? [{ value : "全部" , value_code : "" , label : "全部" }] : [];
				for( let k in addressData['86']  ){
					options.push({
						value : addressData['86'][k],
						value_code : k,
						label : addressData['86'][k],
						children : []
					});
				}
				options.forEach( ( item , i ) => {
					for( let k in addressData[ item.value_code ] ){
						if( this.is_all ){
							item.children.push({
								value : "全部",
								value_code : "",
								label : "全部",
							});
						}
						item.children.push({
							value : addressData[ item.value_code ][k],
							value_code : k,
							label : addressData[ item.value_code ][k],
						});
					}
				});
				this.options = options;
			},
			//全部都要选
			set_options_1(){
				//改变类型
				this.change_type( 1 );
				let options = this.is_all ? [{ value : "全部" , value_code : "" , label : "全部" }] : [];
				for( let k in addressData['86']  ){
					options.push({
						value : addressData['86'][k],
						value_code : k,
						label : addressData['86'][k],
						children : []
					});
				}
				options.forEach( ( item , i ) => {
					if( item.children ){
						if( this.is_all ){
							item.children.push({
								value : "全部",
								value_code : "",
								label : "全部",
							});
						}
					}
					for( let k in addressData[ item.value_code ] ){
						if( item.children ){
							item.children.push({
								value : addressData[ item.value_code ][k],
								value_code : k,
								label : addressData[ item.value_code ][k],
								children : []
							});
						}
					}
					if( item.children ){
						item.children.forEach( ( c_item , i ) => {
							if( c_item.children && this.is_all ){
								c_item.children.push({
									value : "全部",
									value_code : "",
									label : "全部",
								});
							}
							for( let k in addressData[ c_item.value_code ] ){
								c_item.children.push({
									value : addressData[ c_item.value_code ][k],
									value_code : k,
									label : addressData[ c_item.value_code ][k]
								});
							}
						});
					}
				});
				this.options = options;
			},
			init_qfAddres() {
				if (this.user_info.level == 4) {
					this.set_options_3();
				}
				if (this.user_info.level == 5) {
					this.set_options_6();
				}
			}
		},
		props:{
			// type表示1全部都要选 2省市 3市区 4省 5市 6区
			type : {
				default : 1,
				type : Number,
			},
			clearable : {
				default : true,
				type : Boolean
			},
			is_all : {
				default : true,
				type : Boolean
			}
		}
	}
</script>

<style>
</style>
