<template>
	<el-dialog destroy-on-close :close-on-click-modal="false" @close="close" ref="dialog" :title="title" :visible.sync="show" width="1400px">
		<div style="height: 600px;">
			<el-form :inline="true" :model="form" ref="form">
				<el-form-item label="时间范围">
				  <el-date-picker
					  value-format="yyyy-MM-dd"
					  v-model="date_value"
					  type="daterange"
					  range-separator="至"
					  start-placeholder="开始日期"
					  end-placeholder="结束日期">
				  </el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button @click="device_list()" type="primary">查询</el-button>
				</el-form-item>
			</el-form>
			<div style="width: 100%;height: 500px;overflow: auto;">
				<el-table :data="list" border style="width: 100%;" v-loading="loading">
					<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
					<el-table-column prop="type" label="设备类型" >
						<template slot-scope="scope">
							<el-tag disable-transitions>{{scope.row.type == 1 ? '热水器' : ''}}</el-tag>
						</template>
					</el-table-column>
					
					<el-table-column prop="result" label="结果">
						<template slot-scope="scope">
							<el-tag :type="scope.row.result == null ? 'info' : scope.row.result == 0 ? 'danger' : 'success'" disable-transitions>{{scope.row.result == null ? '—' : scope.row.result == 0 ? '失败' : '成功'}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="desc" label="设置详情"></el-table-column>
					<el-table-column prop="fail" label="设置失败信息"></el-table-column>
					<el-table-column prop="createTime" label="上报时间"></el-table-column>
				</el-table>
			</div>
			<div class="page mg_t text_c">
				<el-pagination 
					@current-change="changePage"
					@size-change="sizeChange"
					:page-sizes="[ 10 , 20 , 30 , 40]" 
					:total="total" 
					:currentPage="form.startPage" 
					:pageSize="form.pageSize" 
					background
					layout="total, sizes, prev, pager, next, jumper"
				>
				</el-pagination>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default{
		data(){
			return {
				loading: false,
				form : {
					startPage : 1,
					pageSize : 10,
					deviceNo : "",
					startDate:'',
					endDate:'',
				},
				total : 0,
				list : [],
				show : false,
				title:"设备设置记录",
				date_value:'',
				code:100
			}
		},
		methods: {
			open( deviceNo ){
				this.form.deviceNo = deviceNo;
				this.show = true;
				this.device_list();
			},
			device_list(){
				if( this.date_value != "" && this.date_value != null && this.date_value != undefined ){
					this.form.startDate = this.date_value[0];
					this.form.endDate = this.date_value[1];
				}else{
					this.form.startDate = "";
					this.form.endDate = "";
				}
				this.loading = true;
				this.$api.deviceFuncSetHistory_list( this.form ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
						for( var i = 0 ; i < this.list.length ; i++ ){
							for( var k in this.list[i] ){
								if( this.list[i][k] == null || this.list[i][k] == "" ){
									this.list[i][k] = "—"
								}
							}
						}
					}else{
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			changePage( e ){
				this.form.startPage = e;
				this.device_list();
			},
			sizeChange( e ){
				this.form.pageSize = e;
				this.device_list();
			},
			close( done ){
				this.$refs.form.resetFields();
				this.date_value = ""
			},
		},
	}
</script>

<style>
</style>