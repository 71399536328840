<template>
	<!-- :close="closeLevel()" -->
	<el-dialog destroy-on-close :close-on-click-modal="false" title="编辑" :visible.sync="show" width="450px" @close="closeLevel()">
		<el-form ref="form" :model="form" label-width="90px">
			<el-form-item label="昵称" prop="userName">
				<el-input v-model="form.nickName" placeholder="请输入昵称"></el-input>
			</el-form-item>
			<el-form-item label="用户名称" prop="userName">
				<el-input v-model="form.userName" placeholder="请输入用户名称"></el-input>
			</el-form-item>
			<el-form-item label="用户密码" prop="password">
				<el-input v-model="form.password" type="password" placeholder="请输入用户密码"></el-input>
			</el-form-item>
			<el-form-item label="用户电话" prop="password">
				<el-input v-model="form.phone" type="phone" placeholder="请输入用户电话"></el-input>
			</el-form-item>
			<!-- <el-form-item label="是否为后台管理用户" prop="isAdmin" label-width="200">
				<el-radio-group v-model="form.isAdmin">
					<el-radio :label="0">否</el-radio>
					<el-radio :label="1">是</el-radio>
				</el-radio-group>
			</el-form-item> -->
			<el-form-item label="启用状态" prop="status">
				<el-radio-group v-model="form.status">
					<el-radio :label="1">启用</el-radio>
					<el-radio :label="0">停用</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit" style="margin-right: 20px;">编辑</el-button>
				<el-button @click="show = false">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { TextToCode } from 'element-china-area-data';
	export default {
		data() {
			return {
				show: false,
				isIndividual : 0,
				form: {
					icon: "",
					id: 0,
					isAdmin: 0,
					nickName: "",
					password: "",
					phone: "",
					status: 0,
					userName: ""
				},
				type : 0,
				getRole_list : [],
				monitoringPoint_list : [],
				qf_addres_type : 1,
				address_type : 1,
			}
		},
		methods: {
			open( data ) {
				for( var k in data ){
					for( var l in this.form ){
						if( l == k ){
							this.form[l] = data[k]
						}
					}
				}
				this.show = true;
			},
			close() {
				this.show = false;
			},
			closeLevel(){
				this.reset_form_data();
			},
			onSubmit() {
				console.log( this.form )
				this.$api.user_update( this.form ).then( res => {
					if( res.code == 200 ){
						this.$message({
							type: 'success',
							message: res.message
						});
						this.$refs.form.resetFields();
						this.$emit( 'success' , 'ok' );
						this.show = false;
					}
				});
			},
			reset_form_data(){
				this.form = {
					icon: "",
					id: 0,
					isAdmin: 0,
					nickName: "",
					password: "",
					phone: "",
					status: 0,
					userName: ""
				}
			},
			// async getRole( data ){
			// 	let userData = {};
			// 	if( this.type == 1 ){
			// 		userData.userId = data.id;
			// 		this.form.password = data.password;
			// 		this.form.roleId = data.roleId;
			// 		this.form.userName = data.userName;
			// 		this.form.remark = data.remark;
			// 		this.form.status = data.status;
			// 		this.form.id = data.id;
			// 		this.form.isAdmin = data.isAdmin;
			// 	}
			// 	this.$api.role_getRole( this.type == 1 ? userData : ""  ).then( res => {
			// 		if( res.code == 200 ){
			// 			this.getRole_list = res.data;
			// 			this.show = true;
			// 		}
			// 	});
			// }
		}
	}
</script>

<style lang="scss">
	.box{
		height: 25px;
	}
	.el-dialog__header{
		background: linear-gradient(135deg, #6b6be4 , #15d8f1);
	}
	.el-dialog__title{
		color:#fff
	}
	.el-dialog__header{
		.el-dialog__headerbtn{
			.el-dialog__close{
				color: #fff;
			}
		}
	}
</style>
